import { __decorate, __extends } from "tslib";
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import Pagination from '@/components/Pagination.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
var NewsList = (function (_super) {
    __extends(NewsList, _super);
    function NewsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.page = 1;
        _this.rows = 6;
        return _this;
    }
    NewsList.prototype.mounted = function () {
        this.getNewsList();
    };
    Object.defineProperty(NewsList.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsList.prototype, "newsList", {
        get: function () {
            return detailsModule.newsList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsList.prototype, "newsStickList", {
        get: function () {
            return detailsModule.newsStickList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsList.prototype, "total", {
        get: function () {
            return detailsModule.newsTotal;
        },
        enumerable: false,
        configurable: true
    });
    NewsList.prototype.pageWatch = function () {
        this.getNewsList();
    };
    NewsList.prototype.getNewsList = function () {
        detailsModule.getNewsList({
            page: this.page,
            rows: this.rows,
            id: this.id,
            stick: ''
        });
        document.body.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    };
    __decorate([
        Prop()
    ], NewsList.prototype, "id", void 0);
    __decorate([
        Watch('$data.page')
    ], NewsList.prototype, "pageWatch", null);
    NewsList = __decorate([
        Component({
            components: { CustomHead: CustomHead, Pagination: Pagination }
        })
    ], NewsList);
    return NewsList;
}(Vue));
export default NewsList;

import { __decorate, __extends } from "tslib";
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
var Pagination = (function (_super) {
    __extends(Pagination, _super);
    function Pagination() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pageSize = 10;
        return _this;
    }
    Object.defineProperty(Pagination.prototype, "currentList", {
        get: function () {
            var totalList = Array.from(Array(this.pageCount), function (v, k) { return k + 1; });
            var start = Math.floor(this.page / this.pageSize) * this.pageSize;
            if (this.page % this.pageSize === 0) {
                start -= this.pageSize;
            }
            return totalList.slice(start, start + this.pageSize);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Pagination.prototype, "pageCount", {
        get: function () {
            return Math.ceil(this.total / this.rows);
        },
        enumerable: false,
        configurable: true
    });
    Pagination.prototype.pageClick = function (val) {
        if (Object.getPrototypeOf(val) === Number.prototype) {
            return val + 1;
        }
        else if (val) {
            if (this.page < this.pageCount) {
                return this.page + 1;
            }
            return this.page;
        }
        if (this.page > 1) {
            return this.page - 1;
        }
        return this.page;
    };
    __decorate([
        Prop({ required: true })
    ], Pagination.prototype, "page", void 0);
    __decorate([
        Prop({ required: true })
    ], Pagination.prototype, "rows", void 0);
    __decorate([
        Prop({ required: true })
    ], Pagination.prototype, "total", void 0);
    __decorate([
        Emit('update:page')
    ], Pagination.prototype, "pageClick", null);
    Pagination = __decorate([
        Component
    ], Pagination);
    return Pagination;
}(Vue));
export default Pagination;

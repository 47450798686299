import { render, staticRenderFns } from "./NewsList.vue?vue&type=template&id=c7b8dc72&scoped=true&"
import script from "./NewsList.vue?vue&type=script&lang=ts&"
export * from "./NewsList.vue?vue&type=script&lang=ts&"
import style0 from "./NewsList.vue?vue&type=style&index=0&id=c7b8dc72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7b8dc72",
  null
  
)

export default component.exports